import { useNavigate } from "@solidjs/router";
import { createEffect, createSignal, onMount, Show } from "solid-js";
import { clientRepo } from "~/server/apis/client_repo";
import { SquidSimulateProps } from "~/server/types/order";
import { ButtonRegular } from "~/widgets/button";
import { Divider } from "~/widgets/divider";
import { PhosphorIcon } from "~/widgets/icons";
import { useModal } from "../modal";

type ProformaInvoiceGenerationModalProps = {
  simulate: SquidSimulateProps;
};

export default function ProformaInvoiceGenerationModal(
  props: ProformaInvoiceGenerationModalProps
) {
  const [PIID, setPIID] = createSignal<string | null>(null);
  const [error, setError] = createSignal<boolean | null>(null);
  const [isLoading, setIsLoading] = createSignal(false);
  const navigator = useNavigate();
  const { openModal, closeModal } = useModal()!;

  const generatePI = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const genPI = await clientRepo.generateProformaInvoice({
        totalAmount: props.simulate.totalAmount,
        products: props.simulate.products,
        notes: PIID() ?? undefined,
      });
      if (genPI) {
        closeModal();
        navigator("/proforma-invoice/" + genPI.id, { replace: true });
      }
    } catch (e: any) {
      setError(e.message);
    }
    setIsLoading(false);
  };

  createEffect(() => {
    if (PIID()) {
      setError(null);
    }
  });

  return (
    <div class="flex w-full flex-col lg:w-auto ">
      <div class="flex px-5 py-4">
        <div class="grow text-h5">Generating Proforma Invoice</div>
        <div
          class="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-baseTertiaryDark"
          style={{
            "box-shadow": "0px 1px 2px 0px rgba(0, 0, 0, 0.02)",
          }}
          onClick={() => {
            closeModal();
          }}
        >
          <PhosphorIcon name="x" fontSize={20} size="bold" />
        </div>
      </div>
      <Divider />
      <div class="flex flex-col gap-2 px-5 py-4">
        <div class="text-mediumBold">Give this PI a note</div>
        <input
          class={` w-full rounded-[12px] border p-3   text-normal text-textDark placeholder:text-normal placeholder:text-textNormal focus:border-basePrimaryDark disabled:border-green-200 dark:border-basePrimaryDark lg:w-[400px] ${
            error() ? "border-errorDark" : "border-baseTertiaryDark"
          }`}
          placeholder={"Enter Note (optional)"}
          onInput={(e) => {
            setPIID(e.target.value);
          }}
        />
        <Show when={error()}>
          <div class="my-1 text-f12Bold text-errorDark">{error()}</div>
        </Show>
        <div class=" w-full text-f12  text-textNormal lg:w-[400px]">
          For your internal use only
        </div>
      </div>
      <Divider />
      <div class="p-5">
        <ButtonRegular
          onClick={() => {
            generatePI();
          }}
          isLoading={isLoading()}
          class="w-full rounded-[8px]"
          isRectangular={true}
          isEnabled={!error()}
        >
          <div class="text-textWhite">Generate</div>
        </ButtonRegular>
      </div>
    </div>
  );
}
